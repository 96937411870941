.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 5%;
  margin: 10% 0;
  text-align: center;
}

.wrapper > h2 {
  font-size: 1.5em;
  color: #16a085;
}

@media screen and (min-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
